import {buildUrl} from '@ivosabev/helpers/buildUrl';
import {getCurrentUrl} from '@ivosabev/helpers/getCurrentUrl';
import {useFetcher, useLoaderData} from '@remix-run/react';
import {useCallback, useEffect, useState} from 'react';
import {type Error} from '#/app/types/gql/graphql.js';

type Result<T> = {data: T; errors?: Error[]};

type Params<T> = {
  update?: (prev: Result<T>, next: Result<T>) => Result<T>;
  refetchInterval?: number;
};

export function useLoaderDataAdvanced<T>({refetchInterval, update}: Params<T> = {}): {
  fetchMore: (queries: Record<string, any>) => void;
  isRefetching: boolean;
  refetch: () => void;
} & Result<T> {
  const loaderData = useLoaderData<Result<T>>() as Result<T>;
  const fetcher = useFetcher<T>();

  const [state, setState] = useState<Result<T>>(loaderData);

  const isRefetching = fetcher.state === 'loading';

  const fetchMore = (queries: Record<string, any>) => {
    const pathname = buildUrl(queries);
    fetcher.load(pathname);
  };

  const refetch = useCallback(() => {
    const currentURL = getCurrentUrl();
    fetcher.load(currentURL);
  }, [fetcher]);

  useEffect(() => {
    setState(loaderData);
  }, [loaderData]);

  useEffect(() => {
    if (refetchInterval) {
      const intervalFetchID = setInterval(() => {
        refetch();
      }, refetchInterval);
      return () => {
        clearInterval(intervalFetchID);
      };
    }
    return () => {};
  }, [refetch, refetchInterval]);

  // NOTE: I don't know what is this supposed to do, and it's not working
  useEffect(() => {
    if (fetcher.data) {
      if (update) {
        // @ts-expect-error
        const newState = update(state, fetcher.data);
        setState(newState);
      } else {
        // @ts-expect-error
        setState(fetcher.data);
      }
    }
  }, [fetcher.data, state, update]);

  // Rethrow errors to the root error boundary
  if (state.errors) {
    throw state.errors;
  }

  return {
    ...state,
    fetchMore,
    isRefetching,
    refetch,
  };
}
